import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ListCard from "../ListCard";
import { Typography, Pagination } from "@mui/material";

const baseUrl = "https://faceoff.world/admin/api/v1/news"; // Static base URL
const image_url = `https://faceoff.world/admin/uploads/news_img`;

const NewsList = ({
  listhd,
  bigNewsLimit,
  contentLink,
  categoryId,
  perPage,
}) => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total pages

  const fetchNewsData = async (currentPage) => {
    setLoading(true);
    try {
      const apiUrl = `${baseUrl}?category_id=${categoryId}&page=${currentPage}&per_page=${perPage}`;
      console.log(apiUrl);
      const response = await fetch(apiUrl);
      const result = await response.json();

      if (response.ok && result.status === 200) {
        setNewsData(result.data);
        const itemCount = result.itemCount || 0; 
        setTotalPages(Math.ceil(itemCount / perPage)); // Calculate total pages
      } else {
        console.error("Failed to fetch news:", result.message);
      }
    } catch (error) {
      console.error("Error fetching news:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNewsData(page); // Fetch data for the current page
  }, [page]); // Re-fetch when `page` changes

  const handlePageChange = (event, value) => {
    setPage(value); // Update the current page
  };
  
  return (
    <div className="newslistcntnt_area">
      <Typography
        component="h2"
        sx={{
          textAlign: "center",
          fontSize: "2.2rem",
          fontWeight: 600,
          marginBottom: "30px",
        }}
      >
        {listhd}
      </Typography>
      <div>
        <Box className="newslistcardstyle">
          {loading ? (
            <p>Loading news...</p>
          ) : (
            newsData
              .slice(0, bigNewsLimit)
              .map((news, index) => (
                <ListCard
                  key={index}
                  imageSrc={
                    news.news_image
                      ? `${image_url}/${news.news_image}`
                      : "/upload/noimg.png"
                  }
                  title={news.news_title}
                  content={news.news_description || "Read more for details"}
                  link={news.link || "#"}
                  postid={news.news_id}
                  //apiUrl={baseUrl}
                  category={news.category}
                  fullContentLink={contentLink}
                />
              ))
          )}
          
        </Box>
        {!loading && (
          <Pagination
            count={totalPages} // Total number of pages
            page={page} // Current page
            onChange={handlePageChange} // Handler for page change
            color="primary"
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default NewsList;
