import React from "react";
import Layout from "../../component/Layout";
import NewsDetails from "../../component/NewsDetails/NewsDetails";

const SportsNewsDetailsPage = () => {
  return (
    <Layout>
      <NewsDetails apiUrl="https://faceoff.world/admin/api/v1/news?category_id=6&page=1&per_page=50" />
    </Layout>
  );
};

export default SportsNewsDetailsPage;
