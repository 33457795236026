import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export default function ListCard({
  imageSrc,
  title,
  content,
  wordLimit = 100,
  fullContentLink,
  postid,
}) {
  const truncateContent = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  const truncatedContent = truncateContent(content, wordLimit);
  const fullContenturl = `${fullContentLink}/${postid}`;
  return (
    <Card
      className="trend_card"
      sx={{ width: "320px", marginX: "12px", marginBottom: "30px" }}
    >
      <img src={imageSrc} />
      <CardContent className="trend_hd" sx={{ paddingX: "10" }}>
        <Typography gutterBottom component="h5">
          <a
            href={fullContenturl}
            target="_blank"
            style={{ textDecoration: "none", color: "#000000" }}
          >
            {title}
          </a>
        </Typography>
        <Typography
          className="trend_cntnt"
          variant="body2"
          color="text.secondary"
        >
          <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />
          <a
            href={fullContenturl}
            target="_blank"
            style={{ textDecoration: "none", color: "#000000" }}
          >
            Read More
          </a>
        </Typography>
      </CardContent>
    </Card>
  );
}
