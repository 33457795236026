import React from 'react';
import Layout from '../component/Layout';
const Press = () => {
    return (
        <Layout>
             <h1>this is the Press page</h1>
        </Layout>
       
    );
}

export default Press;
