import React from "react";
import Layout from "../component/Layout";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CatSrc from "../component/CatSrc";
import AsNavFor from "../component/AsNavFor";
import NewsSlider from "../component/NewsSlider";
import EconomySlider from "../component/EconomySlider";
import HomeNewsSection from "../component/homecomponent/HomeNewsSection";
import EntertainmentNewsSection from "../component/homecomponent/EntertainmentNewsSection";
import BreakingNewsSec from "../component/homecomponent/breakingNews/breakingNewsSec";

const Cathd = (prop) => {
  return (
    <Box>
      <Grid container>
        <Grid className="hd_wrap" md={2} xs={12}>
          <Box
            className="hd_box"
            sx={{
              backgroundColor: "#E3000F",
              textAlign: "center",
              marginRight: "10px",
            }}
          >
            <h2>{prop.hd}</h2>
          </Box>
        </Grid>
        <Grid className="hd_line" md={10}>
          <Box
            sx={{
              width: "99%",
              height: "4px",
              backgroundColor: "#E3000F",
              marginBottom: "0",
            }}
          >
            
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const CatBox = (props) => {
  return (
    <div className="cat_area">
      <img src={props.img} alt=" " />
      <h5>{props.name}</h5>
    </div>
  );
};

const createcat = (CatSrc) => {
  return <CatBox key={CatSrc.id} img={CatSrc.imgURL} name={CatSrc.catName} />;
};

const createbrknews = (BrknewsSrc) => {
  return (
    <BreakingNewscard
      key={BrknewsSrc.id}
      img={BrknewsSrc.imgURL}
      hd={BrknewsSrc.hd}
    />
  );
};

const BreakingNewscard = (props) => {
  return (
    <div>
      <Grid
        className="brkcntnt_area"
        container
        spacing={1}
        sx={{ marginTop: "2px" }}
      >
        <Grid lg={4} xs={2}>
          <Box className="brkimg_area">
            <img src={props.img} alt="" />
          </Box>
        </Grid>
        <Grid lg={8} xs={10}>
          <div className="brkcntnt_wrap">
            <h5 className="brkcntnt_hd">{props.hd}</h5>
            <p>2 hrs ago</p>
          </div>
        </Grid>
      </Grid>
      <hr style={{ marginBottom: "0" }}></hr>
    </div>
  );
};


const Home = () => {
  return (
    <Layout>
      <Grid container className="breakinews_container_wrap" spacing={0}>
        <Grid lg={8} md={12}>
          <Box className="vidslide_area" sx={{ backgroundColor: "#000000" }}>
            <AsNavFor />
          </Box>
        </Grid>
        <Grid
          className="breakingnews_wrap"
          lg={4}
          md={12}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignContent: "space-between",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#000000",
              padding: "1px",
              textAlign: "center",
              width: "100%",
            }}
          >
            <h5 className="break_hd">Breaking News</h5>
          </Box>
          <Box className="breakingnews_area">
            <BreakingNewsSec
              //apiUrl="https://faceoff.world/admin/api/v1/news?category_id=22&page=1&per_page=50" // Replace with your actual API endpoint
              categoryId="22"
              perPage="6"
              //smallNewsLimit={3} // Number of cards to display
              smallNewsLimit={50}
              contentLink={"/breaking-news"}
            />
          </Box>
        </Grid>
      </Grid>

      <Box>
        <Cathd hd="Opinion Categories" />
      </Box>
      <div className="cat_wrap">{CatSrc.map(createcat)}</div>
      <Box>
        <Cathd hd="Trending News" />
      </Box>
      <div>
        <NewsSlider />
      </div>
      <Box>
        <Cathd hd="Economy & Business" />
      </Box>
      <div>
        <EconomySlider
          //apiUrl="https://faceoff.world/admin/api/v1/news?category_id=64&page=1&per_page=50" // Replace with your actual API endpoint
          //smallNewsLimit={3} // Number of cards to display
          categoryId="64"
          perPage="6"
          contentLink={"/economy-news"}
          economyNewsLimit={50}
        />
      </div>

      <Box>
        <Cathd hd="Politics" />
      </Box>
      <div>
        <Grid className="news_left_wrap" lg={7} md={12} sm={12}>
          <HomeNewsSection
            //apiUrl="https://faceoff.world/admin/api/v1/news?category_id=53&page=1&per_page=10" // Replace with your actual API endpoint
            categoryId="53"
            perPage="6"
            smallNewsLimit={3} // Number of cards to display
            bigNewsLimit={3}
            contentLink={"/political-news"}
          />
        </Grid>
      </div>

      <Box>
        <Cathd hd="Sports" />
      </Box>
      <div>
        <Grid className="news_left_wrap" lg={7} md={12} sm={12}>
          <HomeNewsSection
            //apiUrl="https://faceoff.world/admin/api/v1/news?category_id=6&page=1&per_page=10" // Replace with your actual API endpoint
            categoryId="6"
            perPage="6"
            smallNewsLimit={3} // Number of cards to display
            bigNewsLimit={3}
            contentLink={"/sports-news"}
          />
        </Grid>
      </div>

      <Box
        className="hd_box"
        sx={{
          backgroundColor: "#E3000F",
          textAlign: "center",
          marginTop: "30px",
        }}
      >
        <Grid
          container
          spacing={0}
          className="ent_hd"
          sx={{ background: "#E3000F", margin: "0" }}
        >
          <Grid className="ent_hd_wrap" lg={2} md={12} sm={12}>
            <h5>Entertainment</h5>
          </Grid>
          <Grid className="right_wrap" md={10} sm={1}></Grid>
        </Grid>
      </Box>

      <Box className="ent_box" sx={{ background: "#E8E8E8" }}>
        <EntertainmentNewsSection
          //apiUrl="https://faceoff.world/admin/api/v1/news?category_id=539&page=1&per_page=10"
          categoryId="539"
          perPage="6"
          economyNewsLimit={2}
          smallNewsLimit={3}
          contentLink={"/entertainment-news"}
        />
      </Box>

      <Box>
        <Cathd hd="Technology" />
      </Box>
      <div>
        <Grid className="news_left_wrap" lg={7} md={12} sm={12}>
          <HomeNewsSection
            //apiUrl="https://faceoff.world/admin/api/v1/news?category_id=35&page=1&per_page=10" // Replace with your actual API endpoint
            categoryId="35"
            perPage="6"
            smallNewsLimit={3} // Number of cards to display
            bigNewsLimit={3}
            contentLink={"/tech-news"}
          />
        </Grid>
      </div>
      <Box>
        <Cathd hd="What's Hot" />
      </Box>
      <div>
        <Grid className="news_left_wrap" lg={7} md={12} sm={12}>
          <HomeNewsSection
            //apiUrl="https://faceoff.world/admin/api/v1/news?category_id=1&page=1&per_page=10" // Replace with your actual API endpoint
            categoryId="1"
            perPage="6"
            smallNewsLimit={3} // Number of cards to display
            bigNewsLimit={3}
            contentLink={"/hot-news"}
          />
        </Grid>
      </div>
    </Layout>
  );
};

export default Home;
