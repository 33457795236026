import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SmallNewsCard from "../SmallnewsCard";
import BigNewsCard from "../BigNewsCard";
import ViewButton from "../ViewButton";
const baseUrl = "https://faceoff.world/admin/api/v1/news"; // Static base URL
const image_url = `https://faceoff.world/admin/uploads`;

const HomeNewsSection = ({
  smallNewsLimit,
  bigNewsLimit,
  contentLink,
  categoryId,
  perPage,
}) => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1); // Current page

  useEffect(() => {
    const apiUrl = `${baseUrl}?category_id=${categoryId}&page=${page}&per_page=${perPage}`;
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(apiUrl);
        const result = await response.json();
        if (response.ok && result.status === 200) {
          setNewsData(result.data);
        } else {
          console.error("Failed to fetch news:", result.message);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [ categoryId, page, perPage]);


  return (
    <div className="smallnewscntnt_area">
      <div>
        <Grid container spacing={0}>
          {/* Left section: Big news cards */}
          <Grid className="news_left_wrap" lg={6} md={12} sm={12}>
            <Box>
              {loading ? (
                <p>Loading big news...</p>
              ) : (
                newsData.slice(0, bigNewsLimit).map((news, index) => (
                  <BigNewsCard
                    key={index}
                    imageSrc={
                      news.news_image
                        ? `${image_url}/news_img/${news.news_image}`
                        : `${image_url}/noimg.png` // Replace with the actual fallback path
                    }
                    title={news.news_title}
                    content={news.news_description || "Read more for details"} // Add fallback content
                    link={news.link || "#"} // Add fallback link
                    postid={news.news_id}
                    category={news.category}
                    fullContentLink={contentLink}
                  />
                ))
              )}
            </Box>
          </Grid>

          {/* Right section: Small news cards */}
          <Grid
            lg={6}
            className="right_wrap"
            sx={{ paddingLeft: "20px", borderLeft: "1px solid #000000" }}
          >
            {loading ? (
              <p>Loading small news...</p>
            ) : (
              newsData
                .slice(bigNewsLimit, bigNewsLimit + smallNewsLimit) // Small news starts after big news
                .map((news, index) => (
                  <div key={index}>
                    <SmallNewsCard
                      imageSrc={
                        news.news_image
                          ? `${image_url}/news_img/${news.news_image}`
                          : `${image_url}/noimg.png` // Replace with the actual fallback path
                      }
                      title={news.news_title}
                      postid={news.news_id}
                      fullContentLink={contentLink}
                    />
                    <hr className="linearea" />
                  </div>
                ))
            )}
          </Grid>
        </Grid>
      </div>
      <Box sx={{ textAlign: "center", marginTop: "20px" }}>
        <ViewButton fullContentLink={contentLink} />
      </Box>
    </div>
  );
};

export default HomeNewsSection;
