import React from "react";
import Layout from "../../component/Layout";
import NewsList from "../../component/NewsList/NewsList";
const EntertainmentListPage = () => {
  return (
    <Layout>
      <NewsList
        listhd={"Entertaimemnt News"}
        contentLink={"/entertainment-news"}
        //apiUrl="https://faceoff.world/admin/api/v1/news?category_id=539&page=1&per_page=50"
        categoryId="539"
        perPage="24"
      />
    </Layout>
  );
};

export default EntertainmentListPage;
