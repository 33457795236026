import React from "react";
import Layout from "../../component/Layout";
import NewsList from "../../component/NewsList/NewsList";
const PoliticsListPage = () => {
  return (
    <Layout>
      <NewsList
        listhd={"Political News"}
        contentLink={"/political-news"}
        //apiUrl="https://faceoff.world/admin/api/v1/news?category_id=53&page=1&per_page=50"
        categoryId="53"
        perPage="24"
      />
    </Layout>
  );
};

export default PoliticsListPage;
