import React from 'react';
import Layout from '../../component/Layout';
import NewsList from '../../component/NewsList/NewsList';
const EconomyListPage = () => {
  return (
    <Layout>
      <NewsList
        listhd={"Business & Economy"}
        //apiUrl="https://faceoff.world/admin/api/v1/news?category_id=64&page=1&per_page=50"
        contentLink={"/economy-news"}
        categoryId="64"
        perPage="24"
      />
    </Layout>
  );
};

export default EconomyListPage;
