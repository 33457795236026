import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Block } from "@mui/icons-material";
const Layout = ({ children }) => {
    return(
        <>
            <Header/>
            <div style={{padding: "0 5%" , maxWidth: '1460px' , display:'Block', margin:'auto', paddingBottom:40}} > {children}</div>
            <Footer />
        </>
        )
}

export default Layout;