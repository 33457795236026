import React from "react";
import Layout from "../../component/Layout";
import NewsList from "../../component/NewsList/NewsList";
const SportsListPage = () => {
  return (
    <Layout>
      <NewsList
        listhd={"Sports News"}
        contentLink={"/sports-news"}
        //apiUrl="https://faceoff.world/admin/api/v1/news?category_id=6&page=1&per_page=50"
        categoryId="6"
        perPage="24"
      />
    </Layout>
  );
};

export default SportsListPage;
