import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";


const BigNewsCard = ({
  imageSrc,
  title,
  content,
  link,
  wordLimit = 200,
  postid,
  category,
  fullContentLink
}) => {
  // Function to truncate the content to the specified word limit
  const truncateContent = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const truncatedContent = truncateContent(content, wordLimit);

  const fullContenturl = `${fullContentLink}/${postid}`;

  return (
    <div className="bigcntnt_area">
      <Box className="bigcard_wrap" spacing={1}>
        <Box className="bigimg_area">
          <img src={imageSrc} alt={title} />
        </Box>
        <Box className="bigcntnt_wrap">
          <h5 className="bigcntnt_hd">{title}</h5>
          <p>
            <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />
            <a href={fullContenturl} target="_blank">
              Read more
            </a>
          </p>
        </Box>
      </Box>
    </div>
  );
};

export default BigNewsCard;
