import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EconomyCard from "./EconomyCard";
import ViewButton from "../component/ViewButton";
import Box from "@mui/material/Box";
import "../App.css";

const baseUrl = "https://faceoff.world/admin/api/v1/news"; // Static base URL
const image_url = `https://faceoff.world/admin/uploads`;

const EconomySlider = ({
  apiUrl,
  economyNewsLimit,
  contentLink,
  categoryId,
  perPage,
}) => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    console.log("Fetching data...");
    const apiUrl = `${baseUrl}?category_id=${categoryId}&page=${page}&per_page=${perPage}`;
    //console.log(apiUrl);
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(apiUrl);
        const result = await response.json();
        console.log("API Response:", result);
        if (response.ok && result.status === 200) {
          setNewsData(result.data);
        } else {
          console.error("Failed to fetch news:", result.message);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [categoryId, page, perPage]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="economysliderwrap">
      <Slider {...settings}>
        {loading ? (
          <p>Loading news...</p>
        ) : (
          newsData.slice(0, economyNewsLimit).map((news, index) => (
            <EconomyCard
              key={index}
              imageSrc={
                news.news_image
                  ? `${image_url}/news_img/${news.news_image}`
                  : `${image_url}/noimg.png` // Replace with the actual fallback path
              }
              title={news.news_title}
              content={news.news_description || "Read more for details"} // Add fallback content
              link={news.link || "#"} // Add fallback link
              postid={news.news_id}
              fullContentLink={contentLink}
            />
          ))
        )}
      </Slider>
      <Box sx={{ textAlign: "center", marginTop: "20px" }}>
        <ViewButton fullContentLink={contentLink} />
      </Box>
    </div>
  );
};

export default EconomySlider;
