const CatSrc = [
    {
        id: 1,
        imgURL: require('../images/categories/all.png'),
        catName: "All in One",


    },
    {
        id: 2,
        imgURL: require('../images/categories/antivirus.png'),
        catName: "Antivirus",


    },
    {
        id: 3,
        imgURL: require('../images/categories/bluetooth_icon.png'),
        catName: "BLUETOOTH Speaker",


    },
    {
        id: 4,
        imgURL: require('../images/categories/cctv.png'),
        catName: "CCTV",


    },
    {
        id: 5,
        imgURL: require('../images/categories/desktop.png'),
        catName: "Desktop",


    },
    {
        id: 6,
        imgURL: require('../images/categories/feature_phone.png'),
        catName: "FEATURE PHONE",


    },
    {
        id: 7,
        imgURL: require('../images/categories/firewall.png'),
        catName: "FIREWALL",


    },
    {
        id: 8,
        imgURL: require('../images/categories/hdd.png'),
        catName: "HARD DISK DRIVES",


    },
    {
        id: 9,
        imgURL: require('../images/categories/laptop.png'),
        catName: "LAPTOP",


    },
    {
        id: 10,
        imgURL: require('../images/categories/printer.png'),
        catName: "MULTI-FUNCTIONAL PRINTER",


    },
    {
        id: 11,
        imgURL: require('../images/categories/powerbank.png'),
        catName: "POWER BANK",


    },
    {
        id: 12,
        imgURL: require('../images/categories/projectror.png'),
        catName: "PROJECTOR",


    },
    {
        id: 13,
        imgURL: require('../images/categories/router.png'),
        catName: "ROUTER",


    },
    {
        id: 14,
        imgURL: require('../images/categories/server.png'),
        catName: "SERVER",


    },
    {
        id: 15,
        imgURL: require('../images/categories/smartphone.png'),
        catName: "Smartphone",


    },
    {
        id: 16,
        imgURL: require('../images/categories/smarttv.png'),
        catName: "SMART TV",


    },
    {
        id: 17,
        imgURL: require('../images/categories/sdd.png'),
        catName: "SOLID STATE DRIVES",


    },
    {
        id: 18,
        imgURL: require('../images/categories/storage.png'),
        catName: "STORAGE",


    },
    {
        id: 19,
        imgURL: require('../images/categories/ups.png'),
        catName: "UNINTERPUTED POWER SUPPLIES",


    },
    {
        id: 20,
        imgURL: require('../images/categories/videoconference.png'),
        catName: "VIDEO CONFERENCING",


    }
]
export default CatSrc;