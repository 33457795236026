import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const SmallNewsCard = ({ imageSrc, title, fullContentLink, postid }) => {
  const fullContenturl = `${fullContentLink}/${postid}`;
  return (
    <div className="smallnewscntnt_area">
      <Grid container className="smallnews_wrap" spacing={1}>
        <Grid item md={4} sm={3} xs={3}>
          <Box className="brkimg_area">
            <img src={imageSrc} alt={title} />
          </Box>
        </Grid>
        <Grid item md={8} sm={9} xs={9}>
          <div className="brkcntnt_hd">
            <a
              href={fullContenturl}
              target="_blank"
              style={{ textDecoration: "none", color:"#000000" }}
            >
              <h5>{title}</h5>
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SmallNewsCard;
