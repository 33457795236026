import * as React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import faceofflogo from '../images/faceofflogo.png';
import search_icon from '../images/search_icon.png';
import home from '../images/home.png';
import {Link} from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { Toolbar } from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import HeadlineSlider from '../component/HeadlineSlider';
import Typography from '@mui/material/Typography';
import '../App.css';


const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);




  
  // hndle menu click
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);

    
  };
  //menu drawer
  const drawer = (
    <Box className="mobile_menubox" onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <ul className="mobile_menu">    
        <li>
          <NavLink to={"/products"}>Hot Products</NavLink>
        </li>
         <li>
          <NavLink to={"/press"}>Press</NavLink>
        </li>
         <li>
          <NavLink to={"/podcasts"}>Podcasts</NavLink>
        </li>
         <li>
          <NavLink to={"/live-interview"}>Live Interview</NavLink>
        </li>
          <li>
          <NavLink to={"/members"}>Members</NavLink>
        </li>
          <li>
          <NavLink to={"/opinion"}>Opinion</NavLink>
        </li>
       
      </ul>
    </Box>
  );
  return (
    <>
      <Grid container spacing={2}>
        <Grid className="logo_wrap" item md={2} xs={6}>
          <Box className="logo_area" sx={{ p: 1 }}>
            {" "}
            <Link to={"/Home"}>
              <img src={faceofflogo} className="hd_logo" alt="" />
            </Link>{" "}
          </Box>
        </Grid>
        <Grid className="searchfield" item md={7} xs={12} m={0} p={0}>
          <div className="searchform">
            <input type="text" placeholder="" />
            <img src={search_icon} className="sicon" alt="" />
          </div>
          <Link to={"/Home"}>
            <img src={home} className="home_icon" alt="" />
          </Link>
        </Grid>
        <Grid className="hd_links" item md={3} xs={6}>
          <Link className="log_link" href="#">
            Login
          </Link>{" "}
          <Link href="#">|</Link> <Link href="#">Register</Link>
        </Grid>
      </Grid>
      <Box>
        <Toolbar className="nav_wrap">
          <IconButton
            className="nav_btn"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{
              mr: 2,
              display: { sm: "none" },
            }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none ",
                lg: "block",
                xl: "block",
              },
              width: "100%",
            }}
          >
            <ul className="navigation_menu">
              <li>
                <NavLink to={"/products"}>Hot Products</NavLink>
              </li>
              <li>
                <NavLink to={"/press"}>Press</NavLink>
              </li>
              <li>
                <NavLink to={"/podcasts"}>Podcasts</NavLink>
              </li>
              <li>
                <NavLink to={"/live-interview"}>Live Interview</NavLink>
              </li>
              <li>
                <NavLink to={"/members"}>Members</NavLink>
              </li>
              <li>
                <NavLink to={"/opinion"}>Opinion</NavLink>
              </li>
            </ul>
          </Box>
        </Toolbar>
        <Box className="mob_nav_wrap" component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              display: { xs: "block", sm: "block", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "240px",
                background: "#E3000F",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Grid container className="headlines_area" sx={{ overflow: "hidden" }}>
          <Grid
            className="headlines_wrap"
            xs={3}
            sx={{ background: "#000000" }}
          >
            <Typography className="headlines_hd" component="h5">
              {" "}
              Headlines
            </Typography>
          </Grid>
          <Grid className="headslider_wrap" xs={9}>
            <HeadlineSlider
              apiUrl="https://faceoff.world/admin/api/v1/scrolling-headlines"
              headlinesNewsLimit="10"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Header;