import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SmallNewsCard from "../breakingNews/BreakingnewsCard";
const baseUrl = "https://faceoff.world/admin/api/v1/news"; // Static base URL
const image_url = `https://faceoff.world/admin/uploads`;

const BreakingNewsSection = ({
  apiUrl,
  smallNewsLimit,
  contentLink,
  categoryId,
  perPage,
}) => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1); // Current page

  useEffect(() => {
    const apiUrl = `${baseUrl}?category_id=${categoryId}&page=${page}&per_page=${perPage}`;
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(apiUrl);
        const result = await response.json();
        if (response.ok && result.status === 200) {
          setNewsData(result.data);
        } else {
          console.error("Failed to fetch news:", result.message);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [categoryId, page, perPage]);

  return (
    <Box className="breaking-news-container" spacing={0}>
      {loading ? (
        <p>Loading small news...</p>
      ) : (
        newsData.slice(0, smallNewsLimit).map((news, index) => (
          <div key={index}>
            <SmallNewsCard
              imageSrc={
                news.news_image
                  ? `${image_url}/news_img/${news.news_image}`
                  : `${image_url}/noimg.png` // Replace with the actual fallback path
              }
              title={news.news_title || " "} // Fallback for missing title
              postid={news.news_id}
              fullContentLink={contentLink}
            />
            {index < smallNewsLimit - 1 && <hr className="line-area" />}{" "}
            {/* Avoid rendering line after the last news item */}
          </div>
        ))
      )}
    </Box>
  );
};

export default BreakingNewsSection;
