import React from 'react';
import Layout from '../component/Layout';
const Podcasts = () => {
    return (
        <Layout>
             <h1>this is the Podcasts page</h1>
        </Layout>
       
    );
}

export default Podcasts;
