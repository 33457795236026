import React from "react";
import Layout from "../../component/Layout";
import NewsDetails from "../../component/NewsDetails/NewsDetails";

const NewsDetailsPage = () => {
  return (
    <Layout>
      <NewsDetails
        //apiUrl="https://faceoff.world/admin/api/v1/news?category_id=22&page=1&per_page=50"
        categoryId="1"
        perPage="24"
      />
    </Layout>
  );
};

export default NewsDetailsPage;
