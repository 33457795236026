import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../App.css';


//const baseUrl = "https://faceoff.world/admin/api/v1/news"; // Static base URL
const image_url = `https://faceoff.world/admin/uploads`;

const HeadlineSlider = ({
  apiUrl, headlinesNewsLimit,
  categoryId,
  perPage,
}) => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

     useEffect(() => {
    console.log("Fetching data...");
    //const apiUrl = `${baseUrl}?category_id=${categoryId}&page=${page}&per_page=${perPage}`;
    
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(apiUrl);
        const result = await response.json();
        console.log("API Response:", result);
        if (response.ok && result.status === 200) {
          setNewsData(result.data);
        } else {
          console.error("Failed to fetch news:", result.message);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [categoryId, page, perPage]);
  
   
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 20000,
        autoplaySpeed: 120,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
        return (
            <div className="headlineSliderwrap">

                <Slider {...settings}>
                    <div className="headlinearea">
                        Can India Challange the Internet’s big MAC
                    </div>
                    {loading ? (
                              <p>Loading news...</p>
                            ) : (
                              newsData.slice(0, headlinesNewsLimit).map((news, index) => (
                                 <div className="headlinearea">
                                  {news.news_title}
                                </div>
                               
                              ))
                            )}
                </Slider>
            </div>
        );
    }


export default HeadlineSlider;