import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const SmallNewsCard = ({ imageSrc, title, postid, fullContentLink }) => {

  const fullContenturl = `${fullContentLink}/${postid}`;
  return (
    <div className="smallnewscntnt_area">
      <Grid container className="smallnews_wrap" spacing={1}>
        <Grid item md={4} sm={3} xs={3}>
          <Box className="smallnewsimg_area">
            <img src={imageSrc} alt={title} />
          </Box>
        </Grid>
        <Grid item md={8} sm={9} xs={9}>
          <div className="smallnewscntnt_wrap">
            <a style={{textDecoration:"none"}} href={fullContenturl} target="_blank">
              <h5 className="smallnewscntnt_hd">{title}</h5>
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SmallNewsCard;
