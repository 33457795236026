import * as React from "react";
import { Link } from "@mui/material";
import Header from "./component/Header";
import Footer from "./component/Footer";
//import MyVideoSlider from './component/MyVideoSlider';
//import BreakingNews from './component/BreakingNews';
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
//import home from './images/home.png';
//import Grid from '@mui/material/Grid';
//import Navbar from './component/Navbar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Products from "./pages/Products";
//import Newsletter from './pages/Newsletter';
import Press from "./pages/Press";
import Podcasts from "./pages/Podcasts";
import LiveInterview from "./pages/Live-Interview";
import Members from "./pages/Members";
import Opinion from "./pages/Opinion";
import PoliticsNewsDetails from "./pages/Politics/PoliticsDetails";
import SportsNewsDetailsPage from "./pages/Sports/SportsDetails";
import HotNewsDetailsPage from "./pages/Hot/HotDetails";
import EntertainmentDetailsPage from "./pages/Entertainment/EntertainmentDetails";
import TechNewsDetailsPage from "./pages/Technology/TechnologyDetails";
import BreakingDetails from "./pages/Breaking/BreakingDetails";
import EconomyDetailsPage from "./pages/Economy/EconomyDetails";
import EconomyListPage from "./pages/Economy/EconomyList";
import TechListPage from "./pages/Technology/TechnologyList";
import PoliticsListPage from "./pages/Politics/PoliticsList";
import EntertainmentListPAgeListPage from "./pages/Entertainment/EntertainmentList";
import HotListPage from "./pages/Hot/HotList";
import SportsListPage from "./pages/Sports/SportsList";

// import Community from './pages/Community';
// import Technology from './pages/Technology';
// import Economy from './pages/Economy';
// import Politics from './pages/Politics';
// import Sports from './pages/Sports';
// import Entertainment from './pages/Entertainment';
// import Hot from './pages/Hot';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  border: "2",
  borderRadius: "12px",
  borderColor: "#000000",
  backgroundColor: "#f0f0f0",
  "&:hover": {
    backgroundColor: "#eeeeee",
  },

  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "60vw",
      height: "15px",
    },
  },
}));

const menuId = "primary-search-account-menu";

function App() {
  const Toplink = styled(Link)({
    paddingRight: 2,
    underline: "none",
    color: "#000000",
  });

  return (
    <>
      <div>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route exact path="/Home" element={<Home />} />
            <Route exact path="/products" element={<Products />} />
            <Route exact path="/press" element={<Press />} />
            <Route exact path="/podcasts" element={<Podcasts />} />
            <Route exact path="/live-interview" element={<LiveInterview />} />
            <Route exact path="/members" element={<Members />} />
            <Route exact path="/opinion" element={<Opinion />} />
            <Route
              exact
              path="/economy-news/:postid"
              element={<EconomyDetailsPage />}
            />
            <Route exact path="/economy-news" element={<EconomyListPage />} />
            
            <Route
              exact
              path="/breaking-news/:postid"
              element={<BreakingDetails />}
            />

            <Route
              exact
              path="/tech-news/:postid"
              element={<TechNewsDetailsPage />}
            />
            <Route exact path="/tech-news" element={<TechListPage />} />

            <Route
              exact
              path="/political-news/:postid"
              element={<PoliticsNewsDetails />}
            />
            <Route
              exact
              path="/political-news"
              element={<PoliticsListPage />}
            />

            <Route
              exact
              path="/entertainment-news/:postid"
              element={<EntertainmentDetailsPage />}
            />
            <Route
              exact
              path="/entertainment-news"
              element={<EntertainmentListPAgeListPage />}
            />

            <Route
              exact
              path="/hot-news/:postid"
              element={<HotNewsDetailsPage />}
            />
            <Route exact path="/hot-news" element={<HotListPage />} />

            <Route
              exact
              path="/sports-news/:postid"
              element={<SportsNewsDetailsPage />}
            />
            <Route exact path="/sports-news" element={<SportsListPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
