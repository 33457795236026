import { Block } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, textFieldClasses, Typography } from '@mui/material';
import { red } from "@mui/material/colors";

const image_url = `https://faceoff.world/admin/uploads`;

const NewsDetails = () => {
  
  const { postid } = useParams(); // Extract postid from the URL
  const [newsDetails, setNewsDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = `https://faceoff.world/admin/api/v1/get-news-item?news_id=${postid}`;
  console.log(postid);
  useEffect(() => {
    const fetchNewsDetails = async () => {
      try {
        const response = await fetch(apiUrl); // Fetch the data
        if (!response.ok) {
          throw new Error("Failed to fetch news data.");
        }

        const result = await response.json(); // Parse the JSON response
        console.log("Fetched Data:", result);

        const newsArray = result.data;
        if (!Array.isArray(newsArray)) {
          throw new Error("Invalid data format received.");
        }

        const matchedNews = newsArray.find(
          (news) => String(news.news_id) === postid
        );

        //console.log("Matched News:", matchedNews);

        if (!matchedNews) {
          throw new Error("News not found.");
        }

        setNewsDetails(matchedNews); // Remove the spread operator

        console.log("After setting state, newsDetails:", matchedNews);

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (postid) {
      fetchNewsDetails(); // Trigger the API call
    }
  }, [apiUrl, postid]); // Re-run effect when apiUrl or postid changes

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return (
      <div className="errortextwrap">
        <p>
          <span style={{ fontSize: "4.5rem" }}>404!</span>
          <br />
          {error}
        </p>
      </div>
    );
  }

  if (!newsDetails) {
    return <p>No details found for this post.</p>;
  }

  return (
    <div>
      {/* Render matched news directly */}
      <div key={newsDetails.news_id}>
        <h1
          style={{ textAlign: "center", margin: "20px auto", fontWeight: 600 }}
        >
          {newsDetails.news_title}
        </h1>
        <Box sx={{ display: "Block", margin: "auto" }}>
          <img
            src={
              newsDetails.news_image
                ? `${image_url}/news_img/${newsDetails.news_image}`
                : `${image_url}/noimg.png` // Replace with the actual fallback path
            }
            alt={newsDetails.news_title}
            style={{
              width: "50%",
              minWidth: "350px",
              maxWidth: "1440px",
              display: "block",
              margin: "30px auto",
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <div
            className="newdetailscontent"
            style={{
              width: "95%",
              maxWidth: "1440px",
              display: "flex",
              flexWrap: "wrap",
            }}
            dangerouslySetInnerHTML={{ __html: newsDetails.news_description }}
          />
        </Box>
      </div>
    </div>
  );
};

export default NewsDetails;
