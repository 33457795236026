import React from "react";
import Box from '@mui/material/Box';

const ViewButton = ({ fullContentLink }) => {
  const fullContenturl = `${fullContentLink}`;
  return (
    <Box>
      <a className="view_btn" href={fullContenturl} target="blank">
        View more
      </a>
    </Box>
  );
};

export default ViewButton;